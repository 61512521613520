import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";

import PsContext from '../../context/PsContext';

class ErrorPage extends Component {

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {

		}
	}


	render(){

		return(
			<div className="container">
				<Row className="pt-50" >
					<Col md={{ span:8, offset: 2}} >
						<div className="text-center">
							<h1>Hmmm…we cant find that page.</h1>
							<div className="px-0 font-16 mt-15" >
								It may have moved or may no longer exist. If you reached this error from a link on our site, please leave us feedback, so we can fix the problem. Regardless, let's help you get where you want to go.
							</div>
							<br />
							<Link to="/" className="btn btn-info" >
								TVMC Home Page
							</Link>
						</div>
					</Col>
				</Row>
			</div>
		);
	}

}

export default ErrorPage;