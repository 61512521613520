import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Carousel, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Image} from 'antd';

import Masonry from 'react-masonry-css';

import PsContext from '../../context/PsContext';

class StatistiCounter extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			dataList: [],
			dataView: [],
			editData: [],
			categories: [],
			selectedCategory: '',
		}
	}
	
	componentDidMount(){
		
		this.loadSliders();				
	}
	
	loadSliders(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'site/v1/dynamicontents?groupname=homepage_statistic_counter').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			
		}
	}
	
	getData(field){
		try{
			var d = this.state.dataList;
			return d[0][field];
		}
		catch(error){ }
	}
	
	
	
	render(){
		
		if(Object.keys(this.state.dataList).length<1){
			return(
			<></>
			);
		}
		
		if(this.getData('active_status')=='0'){
			return(<></>);
		}
		
		return(
			<section id="counts" className="counts">
			  <div className="container" data-aos="fade-up" >

				<div className="row">
					
					{this.state.dataList.map((item,i)=>{
						return  <div className="col-lg-3 col-md-6">
							<div className="count-box">
							<div dangerouslySetInnerHTML={{__html:item.image_path}} />
							  <span data-toggle="counter-up" data-aos="flip-up" >{item.content}</span>
							  <p>{item.title}</p>
							</div>
						  </div>;
					})
					}

				 

				</div>

			  </div>
			</section>
		);
	}
	
}
export default StatistiCounter;
