import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';

import PsContext from '../../context/PsContext';

import EventSkelton from './EventSkelton';
import EventItem from './EventItem';

class Events extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
			dataView: [],
		}
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'site/v1/events').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		return(
			<div className="cb__page__content mb-50" >
				<Helmet>
					<title>Events - {this.context.state.siteCompany.site_title}</title>
					<meta name="keyword" content={this.context.state.siteCompany.site_keyword} />
					<meta name="description" content={this.context.state.siteCompany.site_description} />
				</Helmet>
			
				<div className="container" data-aos="fade-up"  >
					<h1 className="text-center font-weight-600 mt-20 mb-40" >Event Calendar</h1>
					
					{this.state.showLoader ? <EventSkelton /> : null}
					
					<Row>
					{this.state.dataView.map((item,i)=>{
						return <Col md={3}  data-aos="zoom-in" data-aos-delay="100"  >
							<EventItem data={item} />
						</Col>;
					})
					}
					</Row>
					
				</div>
			
			</div>
		);
	}
	
}
export default Events;
