import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';

import PsContext from '../../context/PsContext';
import Copyright from './Copyright';

class Footer extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			
		}		
		
	}
	
	checkSocialLink(showName){
		try{
			var s = this.context.state.siteCompany;
			return (s[showName]=='1');
		}
		catch(error){
			return false;
		}
	}
	
	getSocialLink(linkName){
		try{
			var s = this.context.state.siteCompany;
			return s[linkName];
		}
		catch(error){
			return false;
		}
	}
	
	componentDidMount(){
		
		$(window).scroll(function() {
			if ($(this).scrollTop() > 100) {
			  $('.back-to-top').fadeIn('slow');
			} else {
			  $('.back-to-top').fadeOut('slow');
			}
		  });

		  $('.back-to-top').click(function() {
			$('html, body').animate({scrollTop:0}, 'slow');
			return false;
		  });
		  
	}
	
	render(){
		
		$('html, body').animate({scrollTop:0}, 'slow');
		
		return(
			<footer  id="footer" >
			
				<div className="footer-top">
				  <div className="container">
					<div className="row">

					{/*<div className="col-lg-3 col-md-6 footer-contact">
						<ul className="footerbtns text-xs-center">
						<li><Link to="" className="btn btn-outline-dark">Applying</Link></li>
						<li><Link to="" className="btn btn-outline-dark">Visiting</Link></li>
						<li><Link to="" className="btn btn-outline-dark">Giving</Link></li>
						<li><Link to="" className="btn btn-outline-dark">Careers</Link></li>
						<li><Link to="" className="btn btn-outline-dark">Contact</Link></li>
						</ul>
					</div>*/}

					  <div className="col-lg-3 col-md-6 footer-links">
						<h4 className="text-xs-center">Useful Links</h4>
						<ul className="text-xs-center">
						  <li><i className="bx bx-chevron-right"></i> <Link to="">Campus Map</Link></li>
						  <li><i className="bx bx-chevron-right"></i> <Link to="/finance-assistance">Financial Aid</Link></li>
						  <li><i className="bx bx-chevron-right"></i> <Link to="/library">Our Library</Link></li>
						  <li><i className="bx bx-chevron-right"></i> <Link to="/accreditation-and-ranking">Accreditation</Link></li>
						  <li><i className="bx bx-chevron-right"></i> <Link to="/research-labs">Research Centres</Link></li>
						</ul>
					  </div>

					  {/*<div className="col-lg-3 col-md-6 footer-links">
						<h4 className="text-xs-center">Quick Links</h4>
						<ul className="text-xs-center">
						  <li><i className="bx bx-chevron-right"></i> <Link to="">TVMC Profiles</Link></li>
						  <li><i className="bx bx-chevron-right"></i> <Link to="">Directory</Link></li>
						</ul>
						<br/>
						<h4 className="text-xs-center">Admission</h4>
						<ul className="text-xs-center">
						  <li><i className="bx bx-chevron-right"></i> <Link to="">Under Gradute</Link></li>
						  <li><i className="bx bx-chevron-right"></i> <Link to="">Post Graduate</Link></li>
						</ul>
					  </div>
					  */}
					  
					  <div className="col-lg-3 col-md-6 footer-links">
						<h4 className="text-xs-center ">TVMC Admissions Office</h4>
						<ul className="text-xs-center">
						  <li><i className="bx bx-chevron-right"></i> <a>Phone : 0462-2572944</a></li>
						  <li><i className="bx bx-chevron-right"></i> <a>Email : dean@tvmc.ac.in</a></li>
						</ul>
						<div className="social-links mt-3 text-xs-center">
						{this.checkSocialLink('show_twitter') ? 
							<a href={this.getSocialLink('twitter')} className="twitter" target="_blank" ><i className="bx bxl-twitter"></i></a> 
						: null}
						{this.checkSocialLink('show_facebook') ? 
							<a href={this.getSocialLink('facebook')} className="facebook" target="_blank"  ><i className="bx bxl-facebook"></i></a>
						: null}
						{this.checkSocialLink('show_instagram') ? 
							<a href={this.getSocialLink('instagram')} className="instagram" target="_blank"  ><i className="bx bxl-instagram"></i></a>
						: null}
						{this.checkSocialLink('show_youtube') ? 
							<a href={this.getSocialLink('youtube')} className="youtube" target="_blank"  ><i className="bx bxl-youtube"></i></a>
						: null}
						{this.checkSocialLink('show_skype') ? 
							<a href={this.getSocialLink('skype')} className="skype" target="_blank"  ><i className="bx bxl-skype"></i></a>
						: null}
						{this.checkSocialLink('show_linkedin') ? 
							<a href={this.getSocialLink('linkedin')} className="linkedin" target="_blank"  ><i className="bx bxl-linkedin"></i></a>
						: null}
						 
						</div>
					  </div>

					</div>
				  </div>
				</div>
				
				<Copyright />
				
				<a href="#" className="back-to-top"><i className="icofont-simple-up"></i></a>
				
			</footer>
		);
	}
	
}
export default Footer;
