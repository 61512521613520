import React, { Component } from 'react';
import {
  Spinner 
} from 'react-bootstrap';

class SpinLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: this.props.loading,
    };
  }

  render() {
    if (!this.props.loading) {
	   return ('');
    }
    return (
		<center style={{marginTop: '8%'}} >
			<Spinner animation="border" />
		</center>
    );
  }
}
export default SpinLoader;
