import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Carousel, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';

import PsContext from '../../context/PsContext';
import Callout from './Callout';
import AboutUs from './AboutUs';
import RecentPosts from './RecentPosts';
import StatistiCounter from './StatistiCounter';
import RecentEvents from './RecentEvents';
import RecentTestimonials from './RecentTestimonials';

class HomeSlider extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadSliders();				
	}
	
	loadSliders(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'site/v1/sliders').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			
		}
	}
	
	render(){

		return(
		<div>
			<div className="cb__home__slider" data-aos="zoom-out" >
			
				
					<Carousel>
						{this.state.dataList.map((item, i)=>{
							return <Carousel.Item>
								 <img src={Api.Url+ item.image_path} alt={item.title} style={{width: '100%'}} />
							</Carousel.Item>;
						})
						}
					</Carousel>
				
			
			</div>
			
			<AboutUs />
			
			<RecentPosts />
						
			<RecentEvents />
			
			<StatistiCounter />
			
			<RecentTestimonials />
			
			<Callout />	
			
		</div>
		);
	}
	
}
export default HomeSlider;
