/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import $ from 'jquery';

import PsContext from '../context/PsContext';
import {ADMIN_BASE_PATH} from '../../utils/Variables';


class Layout extends Component {
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			
		}		
	}
	
	render() {
	  
	 
	  
    return (
		<React.Fragment>

      
			
		</React.Fragment>

    );
  }
  
}
export default Layout;
