import React from 'react';
import { Switch, Route, HashRouter, BrowserRouter, } from 'react-router-dom';
import SiteLayout from '../layouts/SiteLayout';
import AdminLayout from '../views/webadmin/layout';

import { ADMIN_BASE_PATH } from '../utils/Variables';

export default () => (

  <BrowserRouter basename="/" >
    <Switch>
      <Route path="/" component={SiteLayout} />
     </Switch>
  </BrowserRouter>
);
