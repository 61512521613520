const COOKIE_KEY = 'nwp221';


// LOCAL

/* const Api = {
  Url: 'http://localhost/tvmc/api/',
  ImageUrl: 'http://localhost/tvmc/api/public/uploads', // Dont put slash in last
  SiteUrl: 'http://localhost/tvmc',
  version: 'v1/',
}; */


const Api = {
  Url: 'https://tvmc.ac.in/webadmin/api/',
  ImageUrl: 'https://tvmc.ac.in/webadmin/api/public/uploads', // Dont put slash in last
  SiteUrl: 'https://tvmc.ac.in',
  version: 'v1/',
};

/* const Api = {
  Url: 'http://'+window.location.hostname+'/webadmin/api/',
  ImageUrl: 'http://'+window.location.hostname+'/webadmin/api/public/uploads', // Dont put slash in last
  SiteUrl: 'https://tvmc.edu.in',
  version: 'v1/',
}; */

export const ADMIN_BASE_PATH = 'webadmin';

const Var = {
  Title: 'Billing',
  Site: '',
  Phone: '9487900120',
  Email: '',
  Address: '',
  City: 'Tirunelveli',
  Pincode: ' 627002',
  RegularCourseFeeForScSt: 0,
  RegularCourseFeeForOthers: 50,
  SelfCourseFeeForScSt: 50,
  SelfCourseFeeForOthers: 50,
  EnableCashPayment: true,
  EnableOnlinePayment: true,
};

export {
  COOKIE_KEY,
  Api,
  Var,
};
