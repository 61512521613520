import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Carousel, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, makeUrl
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Image} from 'antd';

import Masonry from 'react-masonry-css';

import PsContext from '../../context/PsContext';

class RecentPosts extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			dataList: [],
			dataView: [],
			editData: [],
			categories: [],
			selectedCategory: '',
		}
	}
	
	componentDidMount(){
		
		this.loadSliders();				
	}
	
	loadSliders(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'site/v1/home/recentposts?groupname=homepage_recentposts').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						editData: res['data'].data,
						dataList: res['data'].posts,
						dataView: res['data'].posts,
						categories: res['data'].categories,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			
		}
	}
	
	getData(field){
		try{
			var d = this.state.editData;
			return d[0][field];
		}
		catch(error){ }
	}
	
	handleCategoryClick(catId){
		try{
			var d = this.state.dataList;
			var s= [];
			if(catId==''){
				s = d;
			}
			else{
				s = d.filter(function(obj){
					return(obj.category==catId);
				});
			}
			
			this.setState({
				dataView: s,
				selectedCategory: catId,
			});
		}
		catch(error){ }
	}
	
	getCategoryName(catId){
		try{
			var d = this.state.categories;
			var index = d.findIndex(function(obj){
				return(obj.id==catId);
			});
			return d[index]['name'];
		}
		catch(error){ }
	}
	
	render(){
		
		if(Object.keys(this.state.editData).length<1){
			return(
			<></>
			);
		}
		
		const breakpointColumnsObj = {
		  default: 3,
		  1100: 3,
		  700: 2,
		  500: 1
		};
		
		return(
			<section id="portfolio" className="portfolio">
			  <div className="container" data-aos="fade-up" >

				<div className="section-title" >
				{this.getData('link_text')!='' ? <h2><span>{this.getData('link_text')}</span></h2> : null}
				  <h3><span>{this.getData('title')}</span></h3>
				  <p>{this.getData('subtitle')}</p>
				</div>

				<div className="row" data-aos="fade-up" data-aos-delay="100" >
				  <div className="col-lg-12 d-flex justify-content-center">
					<ul id="portfolio-flters">
					  <li data-filter="*" 
							className={this.state.selectedCategory=='' ? "filter-active" : ''}
							onClick={this.handleCategoryClick.bind(this,'')} 
						>Home</li>
					  {this.state.categories.map((item,i)=>{
					  return <li key={i} data-filter=".filter-tvmc" 
								onClick={this.handleCategoryClick.bind(this,item.id)} 
								className={this.state.selectedCategory==item.id ? "filter-active" : ''}
							>{item.name}</li>;
					  })
					  }
					</ul>
				  </div>
				</div>

				<div className="row portfolio-container grid" data-masonry='{ "itemSelector": ".grid-item", "columnWidth": 200 }'   data-aos="fade-up" data-aos-delay="200" >
					<Masonry
						 breakpointCols={breakpointColumnsObj}
						 className="my-masonry-grid"
						 columnClassName="my-masonry-grid_column"
					>
					{this.state.dataView.map((item,i)=>{
						return  <div className="recent_post"  >
							<Link to={"/news/"+item.id+"/"+makeUrl(item.slug)} >
							<Image
								className="img-fluid"
								src={Api.Url+item.feature_image}
								preview={false}
								placeholder={
									<Image src={require('../../../assets/img/ph.jpg')}  preview={false} />
								}
								fallback={require('../../../assets/img/ph.jpg')}
							/>
							<div className="recent_post_info">
							  <h4>{this.getCategoryName(item.category)}</h4>
							  <p>{item.title}</p>
							</div>
							</Link>
						  </div>;
					})
					}
				 </Masonry>

				</div>

			  </div>
			</section>
		);
	}
	
}
export default RecentPosts;
