import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Image} from 'antd';

import PsContext from '../../context/PsContext';
import RoundPageLoader from '../NotFound/RoundPageLoader';

class SingleEvent extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
			dataView: [],
			id: this.props.match.params.id,
			slug: this.props.match.params.slug,
		}
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'site/v1/event?id='+this.state.id).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getData(field){
		try{
			var d = this.state.dataList;
			return d[0][field];
		}
		catch(error){
			
		}
	}
	
	render(){

		return(
			<div className="cb__page__content mb-50" >
				<Helmet>
					<title>Events - {this.context.state.siteCompany.site_title}</title>
					<meta name="keyword" content={this.getData('meta_keyword')} />
					<meta name="description" content={this.getData('meta_description')} />
				</Helmet>
				
				<div className="container" >
				
				{this.state.showLoader ? <RoundPageLoader /> : 
				<div className="cb__single__event " data-aos="fade-up"  >
					<div className="cb__title" >
						<Row>
							<Col md={12} >
								<h2 className="mt-20 mb-20 text-center font-weight-600" >{this.getData('title')}</h2>
							</Col>
						</Row>
					</div>
					
					<Row>
						<Col md={4} >
							<Card className="cb__event__card" >
								<div className="cb__events__post" >
									<figure>
										<Image
											src={Api.Url + this.getData('feature_image')}
											preview={false}
											className="logo-img"
											placeholder={
												<Image src={require('../../../assets/img/middle-bg.jpg')} />
											}
											fallback={require('../../../assets/img/middle-bg.jpg')}
										/>
									</figure>
								</div>
								<Card.Body>
									<h4 className='font-weight-600' >{this.getData('title')}</h4>
									<div className="font-weight-600 mt-30" >
										{momentDate(this.getData('start_date'), 'dddd, MMM DD, YYYY')}
									</div>
									<div>
										{momentDate(this.getData('start_date'), 'hh:MM A')}
											{/*this.getData('end_date')!='' ? <> - {momentDate(this.getData('end_date'), 'hh:MM A')}</> : null*/}
									</div>
									<br /><br />
								</Card.Body>
							</Card>	
						</Col>
						<Col md={8} >
							<div dangerouslySetInnerHTML={{__html: this.getData('content')}} className="pt-5" />
						</Col>
					</Row>
						
				</div>
				}
				
				</div>
				
			
			</div>
		);
	}
	
}
export default SingleEvent;
