/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import $ from 'jquery';

import Notifications from 'react-notify-toast';
import routes from '../views';
import { Header, SideNav, Footer } from '../views/elements';
import {getls} from '../utils';
import nav from '../_nav';
import axios from 'axios';
import AOS from 'aos';

import {Api} from '../utils/Variables';

import PsContext from '../views/context/PsContext';
import LayoutLoader from './LayoutLoader';
import HospitalLoader from './HospitalLoader';
import NotFound from './NotFound';

class SiteLayout extends Component {
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showLoader: true,
			siteData: [],
		}		
		
		this.loadSiteData = this.loadSiteData.bind(this);
		this.campusLinkClick = this.campusLinkClick.bind(this);
		
	}
	
  componentDidMount() {
	
	/* AOS.init({
	  //offset: 200,
	  //duration: 600,
	  easing: 'ease-in-sine',
	  delay: 200,
	  once: false,
	}); */
	
	 //handle link click 
	$(".link-with-campus").on('click', this.campusLinkClick);
	
	
	this.loadSiteData();
  }
  
  campusLinkClick(e){
	  e.preventDefault();
  }
  
  loadSiteData()
  {
	  try{
		 
		  this.setState({showLoader:true});
		  axios.get(Api.Url+'site/v1/meta').then(res=>{
			 if(res['data'].status=='1'){
				 this.context.updateCompany(res['data'].data);
				 this.setState({
					 siteData: res['data'].data,
					 showLoader: false
				  });
			 }
			 else{
				 this.setState({showLoader: false});
			 }
		  });
	  }
	  catch(error){
		  this.setState({showLoader: false});
	  }
  }

	
	
  render() {
	  
	  if(this.state.showLoader){
		  return(
			<HospitalLoader />
		  );
	  }
	  
	  if(!this.state.showLoader && Object.keys(this.state.siteData).length<1){
		  return(
			<NotFound />
		  );
	  }
	  
    return (
	<React.Fragment>

        <Notifications />

          
		  <Header
            routes={routes}
            {...this.props}
          />

			<main id="main">
           
			   <Switch>
				  {routes.map((page) => (
					<Route exact path={page.path} component={page.component} key={page} />
				  ))}
				</Switch>
				
			</main>

			<Footer />
			
      </React.Fragment>

    );
  }
}
export default SiteLayout;
