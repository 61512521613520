import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';


import PsContext from '../../context/PsContext';
import FullPageTopMenu from './FullPageTopMenu';

class DeptWithRightSidebar extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			slug: '',
			dataList: [],

		}
		
	}
	
	
	getData(field){
		try{
			
			var d = this.props.pagedata.page;
			return d[0][field];
		}
		catch(error){ }
	}
	
	componentDidMount(){
		
		var h = $("#ps-dept-page-content").height();
		$("#ps-dept-links").css('max-height', h);
	}
		
	render(){
		
		return(
			<React.Fragment>
				<div>
					
					{/*<FullPageTopMenu {...this.props} />*/}
					
					<div className="container">
						
						<div className="row">
						
							<div className="col-md-10" >
						
								<h1 className="text-center font-weight-600 my-40 font-ff-meta-space"  data-aos="fade-up" >{this.getData('title')}</h1>
						
								<div data-aos="fade-up" dangerouslySetInnerHTML={{__html: this.getData('content')}} id="ps-dept-page-content" />
								
							</div>
							<div className="col-md-2" >
							{this.props.pagedata.similar && Object.keys(this.props.pagedata.similar).length>0 && (<div className="mt-50 font-ff-meta-space ps-dept-sidebar" >
								<b >Departments</b>
								<hr />
								<div id="ps-dept-links" >
									<ul>
										{this.props.pagedata.similar.map((item,i)=>{
											return <li key={i} >
												<Link to={item.slug}>{item.title}</Link>
											</li>
										})}
									</ul>
								</div>
							</div>)}						
							</div>
							
						</div>
						
					</div>		
					
				</div>		

				

			</React.Fragment>
		);
	}
	
}
export default DeptWithRightSidebar;
