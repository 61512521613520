import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';

import PsContext from '../../context/PsContext';

class Copyright extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			
		}		
	}
	
	render(){
		
		return(
			<div className="container py-4">
			  <div className="copyright">
				&copy; Copyright <strong><span>TVMC</span></strong>. All Rights Reserved
			  </div>
			  <div className="credits">
				Designed by <a href="https://peacesoft.in" target="_blank">Peace Soft Technologies</a>
			  </div>
			</div>
		);
	}
	
}
export default Copyright;
