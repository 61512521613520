import React, { Component } from 'react';
import NavBadge from './NavBadge';
import NavSingleItem from './NavSingleItem';
import NavDropdownSingleItem from './NavDropdownSingleItem';

export default class NavDropdownItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  toggle = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({ open: !prevState.open }));
  };
  render() {
    const { item } = this.props;
    const isExpanded = this.state.open ? 'active show' : '';
    const Icon = null;
	const ionIcon = item.ionicon ? <i className={item.ionicon} ></i> : null;
    const ExpandIcon = this.state.open
      ? <i className="ion-chevron-down" />
      : <i className="ion-chevron-right" />;
    return (
      <li className={`nav-item has-submenu ${isExpanded}`}>
        <a href="javascript:;" role="button" onClick={this.toggle} className={`nav-link has-icon dropdown-toggle ${isExpanded}`} >
			{ionIcon} {item.name}
        </a>
        {(this.state.open || this.props.isSidebarCollapsed) && (
          <ul className="">
            {item.children.map((item, index) => (
              <NavDropdownSingleItem item={item} key={index} role={this.props.role} />
            ))}
          </ul>
        )}
      </li>
    );
  }
}

const styles = {
	chevornStyle: {
		position: 'absolute',
		right: '15px',
	}
}