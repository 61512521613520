/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import $ from 'jquery';
import PsContext from './PsContext';
import {
  setlsItem, getlsItem, getls,
} from '../../utils';

// Then create a provider Component
class PsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: getlsItem('isLogged') || 'no',
	  api: getlsItem('loggedApi') || null,
	  loggedApi: getlsItem('loggedApi') || null,
	  loggedUser: getlsItem('loggedUser') ? JSON.parse(getlsItem('loggedUser')) : [],
	  siteCompany: getlsItem('siteCompany') ? JSON.parse(getlsItem('siteCompany')) : [],
    };

    this.updateUserLogin = this.updateUserLogin.bind(this);
    this.updateCompany = this.updateCompany.bind(this);
	this.logout = this.logout.bind(this);
    this.requestHeader = this.requestHeader.bind(this);
    this.setMainBodyP0 = this.setMainBodyP0.bind(this);
  }

  componentDidMount() {
    //$('body').addClass('sidebar-collapse');
  }

  updateUserLogin(userdata, api) {
    setlsItem('loggedUser', JSON.stringify(userdata));
    setlsItem('isLogged', 'YES');
    setlsItem('loggedApi', api);
    this.setState({ loggedUser: userdata, isLogged: 'YES', loggedApi: api, api: api,});
  }

	updateCompany(_com){
		setlsItem('siteCompany', JSON.stringify(_com));	
		this.setState({siteCompany: _com});		
	}
	
  logout() {
	  setlsItem('isLogged', 'no');
    setlsItem('loggedUser', '');
    setlsItem('loggedApi', '');
    setlsItem('role', '');
    this.setState({ loggedUser: [], loggedApi: '', isLogged: 'no' });
  }


  requestHeader() {
    const d = {
      headers: {
        'Api-Key': getls('loginUser', 'api') || 'null',
      },
    };

    return d;
  }
  
  setMainBodyP0(){
	try{
		var el = document.getElementsByClassName("main-body");
		$(el[0]).addClass("p-0");
	}
	catch(error){ }	
  }


  render() {
    return (
      <PsContext.Provider value={{
        state: this.state, 
		updateUserLogin: this.updateUserLogin, 
		requestHeader: this.requestHeader, 
		logout: this.logout, 
		updateCompany: this.updateCompany,
		setMainBodyP0: this.setMainBodyP0,
      }}
      >
        {this.props.children}
      </PsContext.Provider>
    );
  }
}

export default PsContextProvider;
