/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import {Api} from '../utils/Variables';

class NotFound extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showLoader: true,
			siteData: [],
		}		
	}
	
	
  render() {
    return (
		<div >
			
			
			 <div className="container pt-3">
				<div className="row justify-content-center text-center">
				  <div className="col-8 col-lg-6 col-xl-5">
					<img src="https://mimity-admin89.netlify.app/dist/img/404.svg" className="img-fluid" alt="Page not found" />
				  </div>
				  <div className="col-12 my-3">
					<h2>404 Page Not Found</h2>
					<p className="lead text-dark">Oopps. The page you were looking for doesn't exist.</p>
					<p className="lead small text-secondary">You may have mistyped the address or the page may have moved. Try searching below.</p>
					<form className="form-inline d-inline-flex">
					  <span className="input-icon">
						<i className="material-icons">search</i>
						<input type="search" placeholder="Search..." className="form-control shadow-none bg-light w-100" />
					  </span>
					</form>
					&emsp;
					<a href={Api.SiteUrl} className='btn btn-primary' >
						Go To Home
					</a>
				  </div>
				</div>
			  </div>
			  
		</div>

    );
  }
}
export default NotFound;
