import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';

import PsContext from '../../context/PsContext';
import Pages from '../Pages';
import FullPageTopMenu from '../Pages/FullPageTopMenu';
import RoundPageLoader from './RoundPageLoader';
import ErroPage from './errorPage';

class NotFound extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			slug: '',
			dataList: [],
			menu: [],
			yoffset: 0,
		}
		
		this.windowScroll = this.windowScroll.bind(this);
	}
	
	componentDidMount(){
		document.addEventListener('scroll',this.windowScroll);
		this.state.showLoader = true;
		this.state.slug = this.props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadData();
	}
	
	componentWillReceiveProps(props){
		
		this.state.slug = props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadData();
		//this.forceUpdate();
	}
	
	windowScroll()
	{
		try{
			var height = $(window).scrollTop();
			
			if(height>40){				
				$("body").addClass('page-scroll');
				//$("#topbar").addClass('hide-top-bar');
			}
			else{
				$("body").removeClass('page-scroll');
			}
			
			
		}
		catch(er){
			
		}
	}
	
	componentWillUnmount()
	{
		$("body").removeClass('page-scroll');
		document.removeEventListener('scroll',this.windowScroll);
	}
	
	loadData(){
		try{
			this.setState({
				dataList: [],
				showLoader: true,
			});
			axios.get(Api.Url+'site/v1/page?slug='+this.state.slug).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						menu: res['data'].data.menu || [],
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
		
	}
	
	getData(field){
		try{
			var d = this.state.dataList.page;
			return d[0][field];
		}
		catch(error){ }
	}
	
	render(){

		return(
			<div className="cb__page__content" >
			
			{this.state.menu && Object.keys(this.state.menu).length>0 && (
				<FullPageTopMenu menus={this.state.menu}  />
			)}			
			{this.state.showLoader ? <RoundPageLoader />
			: Object.keys(this.state.dataList).length> 0 ? <>
				{this.getData('row_type')=='page' ? <>
					<Pages pagedata={this.state.dataList} {...this.props} />
				</> : <>
					<ErroPage />
				</>
				}
			</> : <>
				<ErroPage />
			</>
			}
			
			</div>
		);
	}
	
}
export default NotFound;
