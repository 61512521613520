import React, { Component } from 'react';
import $ from 'jquery';
import {Link, Redirect} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import {Api} from '../../../utils/Variables';

import PsContext from '../../context/PsContext';

import UserLogin from '../userLogin/userLogin';

class CampusFirstLine extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			slug: '',
			dataList: [],
			
			images: [],
			photoIndex: 0,
			isOpen: false,
		}
		
		
	}
	
	componentDidMount(){
		
		
	}
	
	
	
	render(){
		
		if(this.context.state.isLogged=='YES'){
			return(<Redirect to="/" />);
		}
		
			return(
				<div className="cb__page__contents" >
					<h1 className="text-center font-weight-600 my-40 font-ff-meta-space"  data-aos="fade-down" >Campus Firstline</h1>
					<Row>
						<Col md={{ span:4, offset: 4}} >
							
							<UserLogin />
						
						</Col>
					</Row>
					
				</div>
			);
		
	}
	
}
export default CampusFirstLine;
