import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';

import PsContext from '../../context/PsContext';


class RightSidebar extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			slug: '',
			dataList: [],
		}
	}
	
	componentDidMount(){
		
	}
	
	getData(field){
		try{
			var d = this.props.pagedata.page;
			return d[0][field];
		}
		catch(error){ }
	}
	
	render(){

		return(
			<React.Fragment>
				<div>
					
					<div className="container">
						<div className="row">
							<div className="col-md-9" >
								<h1 className="text-center font-weight-600 mt-20" >{this.getData('title')}</h1>
						
								<div dangerouslySetInnerHTML={{__html: this.getData('content')}} />
							</div>
							<div className="col-md-3">
								<Card>
									<Card.Body>
									
									</Card.Body>
								</Card>
							</div>
						</div>
					</div>		
					
				</div>						
			</React.Fragment>
		);
	}
	
}
export default RightSidebar;
