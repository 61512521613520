import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Carousel, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, makeUrl
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Image} from 'antd';

import Masonry from 'react-masonry-css';

import PsContext from '../../context/PsContext';

class RecentEvents extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			dataList: [],
			dataView: [],
			editData: [],
			categories: [],
			selectedCategory: '',
		}
	}
	
	componentDidMount(){
		
		this.loadSliders();				
	}
	
	loadSliders(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'site/v1/home/recentevents?groupname=homepage_recent_events&type=event').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						editData: res['data'].data,
						dataList: res['data'].posts,
						dataView: res['data'].posts,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			console.log(error);
		}
	}
	
	getData(field){
		try{
			var d = this.state.editData;
			return d[0][field];
		}
		catch(error){ }
	}
	
	handleCategoryClick(catId){
		try{
			var d = this.state.dataList;
			var s= [];
			if(catId==''){
				s = d;
			}
			else{
				s = d.filter(function(obj){
					return(obj.category==catId);
				});
			}
			
			this.setState({
				dataView: s,
				selectedCategory: catId,
			});
		}
		catch(error){ }
	}
	
	getCategoryName(catId){
		try{
			var d = this.state.categories;
			var index = d.findIndex(function(obj){
				return(obj.id==catId);
			});
			return d[index]['name'];
		}
		catch(error){ }
	}
	
	render(){
		
		if(Object.keys(this.state.editData).length<1){
			return(
			<></>
			);
		}
		
		var bg = this.getData('image_path');
		bg = bg!='' && bg!=null && bg.length>5 ? Api.Url+bg : null;
		
		return(
		<section id="services" className="services" style={bg!=null ? {backgroundImage: `url(${bg})`} : {textAlign:'center'}} >
		  <div className="container" data-aos="fade-up" >

			<div className="section-title">
			   {this.getData('link_text')!='' ? <h2><span>{this.getData('link_text')}</span></h2> : null}
			   <h3><span>{this.getData('title')}</span></h3>
				<p>{this.getData('subtitle')}</p>
			</div>

			<div className="row">
			
			{this.state.dataView.map((item,i)=>{
				return <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100" >
					<Link to={"/events/"+item.id+"/"+makeUrl(item.slug)} >
					<div className="icon-box">
					  <Image
							className="img-responsive"
							src={Api.Url+item.feature_image}
							preview={false}
							placeholder={
								<Image src={require('../../../assets/img/ph.jpg')}  preview={false} />
							}
							fallback={require('../../../assets/img/ph.jpg')}
						/>
						<h4><span><small>{momentDate(item.start_date,'MMM DD, YYYY')}</small></span><br/>{item.title}</h4>
							{/*<p>{item.title}</p>*/}
					</div>
					</Link>
				  </div>;
			})
			}
			
			</div>

		  </div>
		</section>
		);
	}
	
}
export default RecentEvents;
