import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import {Image, Skeleton } from 'antd';
import axios from 'axios';

import PsContext from '../../context/PsContext';

class EventSkelton extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
			dataView: [],
		}
	}
	
	
	
	render(){
		
		var sk = [];
		for(var i=0;i<12;i++){
			sk.push(
				<Col md={3} >
					
					<Skeleton.Input style={{ width: '250px', height: '250px',}} active={true} size={"large"} />
					
					<Skeleton.Input style={{ width: '250px', height: '30px', marginTop: '10px'}} active={true} />
					<Skeleton.Input style={{ width: '250px', height: '20px', marginTop: '10px'}} active={true} />
					
				</Col>	
			);
		}
		
		return(
			<Row>
			{sk}
			</Row>
		);
	}
	
}
export default EventSkelton;
