import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import { message } from 'antd';

import PsContext from '../../context/PsContext';

class UserLogin extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		this.setState({showLoader: true});
		axios.post(Api.Url+'site/v1/login', $("#frmPUserLogin").serialize()).then(res=>{
			if(res['data'].status=='1'){
				
				this.context.updateUserLogin(res['data'].data, 'xxx');
			}
			else{
				message.error(res['data'].message || 'Invalid');
				this.setState({showLoader: false});
			}
		});
	}
	
	render(){

		return(
			<Card style={{ borderTop: '4px solid var(--theme-topbar)' }} data-aos="fade-up" >
				<Card.Body>
					<span className="font-13" >You need to verify your authorization to access this page.</span>
					<hr />
					<form action="" method="post" id="frmPUserLogin" onSubmit={this.handleFormSubmit} >
						<Row>
							<Col md={12} >
								<label className="font-14" >Username </label>
								<Form.Control 
									type="text"
									name="username"
									placeholder="Your Username" 
									size="sm"
									required
								/>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={12} >
								<label className="font-13" >Password </label>
								<Form.Control 
									type="password"
									name="password"
									size="sm"
									placeholder="Your Password" 
									required
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={12} >
								<div className="text-right" >
									<Button type="submit" variant="primary" size="md" block >
										Enter to Campus
									</Button>
								</div>
							</Col>
						</Row>
						
					</form>
					<hr />
					<div className="font-13 text-justify" >
					<span className="text-danger">Note : </span> <br />
						1. Faculty and Students who have not received the authorization are requested to kindly contact the campus web administrator <br/>
						2. Regulators and Administrators who need to view this page may kindly send a request mail to <a href="mailto:dean@tvmc.ac.in">dean@tvmc.ac.in </a>
					</div>
					
				</Card.Body>
			</Card>
		);
	}
	
}
export default UserLogin;
