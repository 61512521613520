import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, makeUrl
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Image} from 'antd';

import PsContext from '../../context/PsContext';
import RoundPageLoader from '../NotFound/RoundPageLoader';

class SingleNews extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
			dataView: [],
			id: '',
			slug: '',
			previous: [],
			next: [],
		}
	}
	
	componentDidMount(){
		this.state.id = this.props.match.params.id;
		this.state.slug = this.props.match.params.slug;
		this.loadData();
	}
	
	componentWillReceiveProps(props){
		this.state.id = props.match.params.id;
		this.state.slug = props.match.params.slug;
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'site/v1/event?type=post&id='+this.state.id).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						previous: res['data'].previous,
						next: res['data'].next,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getData(field){
		try{
			var d = this.state.dataList;
			return d[0][field];
		}
		catch(error){
			
		}
	}
	
	getPrevious(field){
		try{
			var d = this.state.previous;
			return d[0][field];
		}
		catch(error){
			
		}
	}
	
	getNext(field){
		try{
			var d = this.state.next;
			var s = d[0][field];
			return (s=='' || s==undefined || s==null || s.length<1) ? false : s;
		}
		catch(error){
			return false;
		}
	}
	
	render(){

		return(
			<div className="cb__page__content mb-50" >
				<Helmet>
					<title>Events - {this.context.state.siteCompany.site_title}</title>
					<meta name="keyword" content={this.getData('meta_keyword') || this.context.state.siteCompany.site_keyword} />
					<meta name="description" content={this.getData('meta_description') || this.context.state.siteCompany.site_description} />
				</Helmet>
				
				<div className="container" >
				
				{this.state.showLoader ? <RoundPageLoader /> : 
				<div className="cb__single__event " data-aos="fade-up"  >
					<div className="cb__title" >
						<Row>
							<Col md={{span:10, offset:1}} >
								<h2 className="mt-20 mb-20 text-center font-weight-600" >{this.getData('title')}</h2>
							</Col>
						</Row>
					</div>
					
					<Row>
						<Col md={{span:10, offset:1}} >
							<figure>
								<Image
									src={Api.Url + this.getData('feature_image')}
									preview={false}
									className=""
								
									placeholder={
										<Image src={require('../../../assets/img/middle-bg.jpg')} />
									}
									fallback={require('../../../assets/img/middle-bg.jpg')}
								/>
							</figure>
					
							<hr />
							<div dangerouslySetInnerHTML={{__html: this.getData('content')}} className="pt-5" />
							<hr />
						</Col>
					</Row>
					
					<Row>
						<Col md={{span:10, offset:1}} >
							
							<Row>
								<Col md={12} >
									<h3 className="text-center mb-25" >What to read next:</h3>
								</Col>
								
								<Col md={6} >
									{this.getPrevious('id')!='' && this.getPrevious('id')!=undefined && this.getPrevious('id')!=null ?  <Link to={"/news/"+this.getPrevious('id')+"/"+makeUrl(this.getPrevious('slug'))} >
										<Card className="cb__event__card"  data-aos="zoom-in" data-aos-delay="100" >
											<div className="cb__events__post" >
												<figure>
													<Image
														src={Api.Url + this.getPrevious('feature_image')}
														preview={false}
														placeholder={
															<Image src={require('../../../assets/img/middle-bg.jpg')} />
														}
														fallback={require('../../../assets/img/middle-bg.jpg')}
													/>
												</figure>
												<Card.Body>
													<h4 className="text-center font-weight-600" >{this.getPrevious('title')}</h4>
												</Card.Body>
											</div>
										</Card>
									</Link> : null}
								</Col>
								
							
								<Col md={6} >
									{this.getNext('id')!='' && this.getNext('id')!=undefined && this.getNext('id')!=null ? <Link to={"/news/"+this.getNext('id')+"/"+makeUrl(this.getNext('slug'))} >
										<Card className="cb__event__card"  data-aos="zoom-in" data-aos-delay="100"  >
											<div className="cb__events__post" >
												<figure>
													<Image
														src={Api.Url + this.getNext('feature_image')}
														preview={false}
														placeholder={
															<Image src={require('../../../assets/img/middle-bg.jpg')} />
														}
														fallback={require('../../../assets/img/middle-bg.jpg')}
													/>
												</figure>
												<Card.Body>
													<h4 className="text-center font-weight-600" >{this.getNext('title')}</h4>
												</Card.Body>
											</div>
										</Card>
									</Link> : null}
								</Col>
							</Row>
							
						</Col>
					</Row>
					
					{/*<Row>
						<Col md={4} >
							<Card className="cb__event__card" >
								<div className="cb__events__post" >
									<figure>
										<Image
											src={Api.Url + this.getData('feature_image')}
											preview={false}
											className="logo-img"
											placeholder={
												<Image src={require('../../../assets/img/middle-bg.jpg')} />
											}
											fallback={require('../../../assets/img/middle-bg.jpg')}
										/>
									</figure>
								</div>
								<Card.Body>
									<h4 className='font-weight-600' >{this.getData('title')}</h4>
									<div className="font-weight-600 mt-30" >
										{momentDate(this.getData('start_date'), 'dddd, MMM DD, YYYY')}
									</div>
									<div>
										{momentDate(this.getData('start_date'), 'hh:MM A')}
									</div>
									<br /><br />
								</Card.Body>
							</Card>	
						</Col>
						<Col md={8} >
							<div dangerouslySetInnerHTML={{__html: this.getData('content')}} className="pt-5" />
						</Col>
					</Row>*/}
						
				</div>
				}
				
				</div>
				
			
			</div>
		);
	}
	
}
export default SingleNews;
