import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, makeUrl
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import {Image} from 'antd';
import axios from 'axios';

import PsContext from '../../context/PsContext';

class NewsItem extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
			dataView: [],
		}
	}
	
	
	render(){
		
		const data = this.props.data;
		
		return(
			<Link to={"/news/"+data.id+"/"+makeUrl(data.slug)} >
				<Card className="cb__event__card my-20" >
					<div className="cb__events__post" >
						<figure>
							<Image
								src={Api.Url + data.feature_image} 
								preview={false}
								className=""
								style={{width: '100%'}} 
								placeholder={
									<Image src={require('../../../assets/img/middle-bg.jpg')} />
								}
								fallback={require('../../../assets/img/middle-bg.jpg')}
							/>
						</figure>
					</div>
					<Card.Body>
						<h6 style={{color: '#3f51b5', textTransform: 'uppercase'}} className="font-weight-600 font-14" >{data.category_name}</h6>
						<h5 className="font-weight-600 mt-15" >{data.title}</h5>
					</Card.Body>
				</Card>		
			</Link>			
		);
	}
	
}
export default NewsItem;
