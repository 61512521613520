import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Carousel, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';

import {Api} from '../../../utils/Variables';

import PsContext from '../../context/PsContext';

class Callout extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadSliders();				
	}
	

	
	loadSliders(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'site/v1/dynamicontents?groupname=homepage_callout').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			
		}
	}
	
	getData(field){
		try{
			var d = this.state.dataList;
			return d[0][field];
		}
		catch(error){ }
	}
	
	render(){
		
		if(Object.keys(this.state.dataList).length<1){
			return(
			<></>
			);
		}
		
		if(this.getData('active_status')=='0'){
			return(<></>);
		}
		
		return(
			<div className="special-section" data-aos="fade-up" >
				  <div className="container" >
					<h2>{this.getData('title')}</h2>
					<p>{this.getData('content')}</p>
						{this.getData('link_text')!='' ? <p>
						<a href={this.getData('link_to')} className="btn btn-lg btn-outline-warning">{this.getData('link_text')} &raquo;</a>
						</p> : null}
				  </div>
			 </div>
		);
	}
	
}
export default Callout;
