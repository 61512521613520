import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Carousel, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';

import {Api} from '../../../utils/Variables';

import PsContext from '../../context/PsContext';

class AboutUs extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadSliders();				
	}
	

	
	loadSliders(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'site/v1/dynamicontents?groupname=homepage_aboutus').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			
		}
	}
	
	getData(field){
		try{
			var d = this.state.dataList;
			return d[0][field];
		}
		catch(error){ }
	}
	
	render(){
		
		if(Object.keys(this.state.dataList).length<1){
			return(
			<></>
			);
		}
		
		if(this.getData('active_status')=='0'){
			return(<></>);
		}
		
		var bg = this.getData('image_path');
		bg = bg!='' && bg!=null && bg.length>5 ? Api.Url+bg : null;
		
		return(
			<section id="aboutsec" className="aboutsec">
			  <div className="container" data-aos="fade-up">
				<div className="row">
				  <div className="col-lg-6 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
					<h3>{this.getData('title')} </h3>
					<p className="colour2">{this.getData('subtitle')}</p>
					<p>
					<div data-aos="fade-up" dangerouslySetInnerHTML={{__html: this.getData('content')}} />
					<a href={this.getData('link_to')} className="btn btn-lg btn-outline-secondary mt-15">{this.getData('link_text')} &raquo;</a>
					</p>
				  </div>

				  <div className="col-lg-6 col-md-6 align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
					  <img src={bg} className="img-responsive" />
				  </div>


				</div>

			  </div>
			</section>
		);
	}
	
}
export default AboutUs;
