import React, { Component } from 'react';
import './index.css';

class PageLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: this.props.loading,
    };
  }

  render() {
    if (!this.props.loading) {
	   return ('');
    }
    return (
      <div className="lds-ellipsis-first-full">
        {/* <div className="lds-ellipsis-full">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
		</div>	*/}
        <div className="lds-roller">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <br />
        Loading..
      </div>
    );
  }
}
export default PageLoader;
