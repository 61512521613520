/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import 'jquery-confirm';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'suneditor/dist/css/suneditor.min.css';
import 'antd/dist/antd.css';
import 'izitoast/dist/css/iziToast.min.css';
import 'aos/dist/aos.css';
import 'react-image-lightbox/style.css';

import './assets/material-design-icons-iconfont/material-design-icons.min.css';
import './assets/icofont/icofont.min.css';
import './assets/css/style.min.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/css/style.css';
import './assets/custom.css';

import App from './App';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
