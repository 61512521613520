/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import './loader.scss';

class LayoutLoader extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showLoader: true,
			siteData: [],
		}		
	}
	
	
  render() {
    return (
		<div className="ls_container" >
			<div className="infinityChrome">
				<div></div>
				<div></div>
				<div></div>
			</div>


			<div className="infinity">
				<div>
					<span></span>
				</div>
				<div>
					<span></span>
				</div>
				<div>
					<span></span>
				</div>
			</div>


			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{display: 'none'}} >
				<defs>
					<filter id="goo">
						<feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur" />
						<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
						<feBlend in="SourceGraphic" in2="goo" />
					</filter>
				</defs>
			</svg>
		</div>

    );
  }
}
export default LayoutLoader;
