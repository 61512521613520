import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import PsContext from '../../context/PsContext';

class RoundPageLoader extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			slug: '',
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		
	}
	
	

	
	render(){

		const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

		return(
			<div style={{
				minHeight: 'calc(100vh - 50px)',
				paddingTop: '100px',
				textAlign: 'center',
			}} >
				
				<Spin indicator={antIcon} />
				<p className="mt-20" >Loading..</p>
				
			</div>
		);
	}
	
}
export default RoundPageLoader;
