import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';


class Menu extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showLoader: false,
			showModal: true,
		}		
		
	}
	
	toggleMobileNav(){
		 $('body').toggleClass('mobile-nav-active');
		  $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
		  $('.mobile-nav-overly').toggle();
	}
	
	componentDidMount(){
		
	}
	
	componentWillMount(){
		if ($('.nav-menu').length) {
			/* var $mobile_nav = $('.nav-menu').clone().prop({
			  class: 'mobile-nav d-lg-none'
			});
			$('body').append($mobile_nav); */
			$('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none" onClick='+this.toggleMobileNav+'><i class="icofont-navigation-menu"></i></button>'); 
			$('body').append('<div class="mobile-nav-overly"></div>');

			$(document).on('click', '.mobile-nav-toggle', function(e) {
				$('body').toggleClass('mobile-nav-active');
				$('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
				$('.mobile-nav-overly').toggle();
			}); 

			$(document).on('click', '.mobile-nav .drop-down > a', function(e) {
			  e.preventDefault();
			  $(this).next().slideToggle(300);
			  $(this).parent().toggleClass('active');
			});

			$(document).click(function(e) {
			  var container = $(".mobile-nav, .mobile-nav-toggle");
			  if (!container.is(e.target) && container.has(e.target).length === 0) {
				if ($('body').hasClass('mobile-nav-active')) {
				  $('body').removeClass('mobile-nav-active');
				  $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
				  $('.mobile-nav-overly').fadeOut();
				}
			  }
			});
			
			$(document).on('click', 'a.cb__page__link', function(e) {
				$('body').toggleClass('mobile-nav-active');
				$('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
				$('.mobile-nav-overly').toggle();
			});
			
		  } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
			$(".mobile-nav, .mobile-nav-toggle").hide();
		  }
	}
	
	getSecondLevelChild(menus){
		
		var rv = [];
		menus.map((menu,key)=>{
			
			if(menu.children && Object.keys(menu.children).length>0){
				
			}
			else{

				if(this.isNewLink(menu.menulink)){
					rv.push(<li>
						<a activeClassName="active" href={menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} >{menu.title}</a>
					</li>);
				}
				else{
					rv.push(<li>
						<NavLink activeClassName="active" className="cb__page__link" to={"/"+menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} exact >{menu.title}</NavLink>
					</li>);
				}
			}
		});
		
		return rv;
	}
	
	getFirstLevelChild(menus){
		
		var rv = [];
		menus.map((menu,key)=>{
			
			if(menu.children && Object.keys(menu.children).length>0){
				rv.push(<li className="drop-down" >
					<a href="javascript:;">{menu.title}</a>
					<ul className="">
						{this.getSecondLevelChild(menu.children)}
					</ul>					
				</li>);
			}
			else{

				if(this.isNewLink(menu.menulink)){
					rv.push(<li>
						<a activeClassName="active" href={menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} >{menu.title}</a>
					</li>);
				}
				else{
					rv.push(<li>
						<NavLink activeClassName="active" className="cb__page__link" to={"/"+menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} exact >{menu.title}</NavLink>
					</li>);
				}
			}
		});
		
		return rv;
	}
	
	getMenus(menus){
		
		var rv = [];
		menus.map((menu,key)=>{
			
			if(menu.children && Object.keys(menu.children).length>0){
				rv.push(<li className="drop-down" >
					<a href="javascript:;">{menu.title}</a>
					<ul className="">
						{this.getFirstLevelChild(menu.children)}
					</ul>					
				</li>);
			}
			else{
				if(this.isNewLink(menu.menulink)){
					rv.push(<li>
						<a href={+menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} >{menu.title}</a>
					</li>);
				}
				else{
					rv.push(<li>
						<NavLink activeClassName="active" className="cb__page__link" to={"/"+menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} exact >{menu.title}</NavLink>
					</li>);
				}
			}
		});
		
		return rv;
	}
	
	isNewLink(val){
		try{
			if(val.substr(0,4)=='http')
				return true;
			
			return false;
		}
		catch(error){
			console.log(error);
			return false;
		}
	}
	
	render(){
		
		
		return(
			<>
				<nav className="nav-menu d-none d-lg-block mainmenu">
					<ul>
						{this.getMenus(this.props.data)}				
					</ul>					
				</nav>
				
				<nav className="mobile-nav d-lg-none">
					<ul>
						{this.getMenus(this.props.data)}				
					</ul>					
				</nav>
				
			</>
		);
	}
	
}
export default Menu;
