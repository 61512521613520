import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';

import PsContext from '../../context/PsContext';

import HomeSlider from '../HomeSlider';

class Dashboard extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
		}
	}
	
	componentDidMount(){
		
		
	}
	
	render(){

		return(
			<React.Fragment>
				<Helmet>
					<title>{this.context.state.siteCompany.site_title}</title>
					<meta name="keyword" content={this.context.state.siteCompany.site_keyword} />
					<meta name="description" content={this.context.state.siteCompany.site_description} />
				</Helmet>
			
				<HomeSlider />
			
			</React.Fragment>
		);
	}
	
}
export default Dashboard;
