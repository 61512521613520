import Dashboard from './pages/Dashboard';
import Events from './pages/Events';
import SingleEvent from './pages/Events/SingleEvent';

import News from './pages/News';
import SingleNews from './pages/News/SingleNews';

import NotFound from './pages/NotFound';
import CampusFirstLine from './pages/Pages/campusFirstLine';


const pageList = [
  { name: 'Dashboard', path: '/', component: Dashboard },
  { name: 'Events', path: '/events', component: Events },
  { name: 'Events', path: '/events/:id/:slug', component: SingleEvent },
  
  { name: 'Campus FirstLine', path: '/campus-firstline', component: CampusFirstLine },
  
  { name: 'News', path: '/news', component: News },
  { name: 'News', path: '/news/:id/:slug', component: SingleNews },
  
  { name: 'Dashboard', path: '*', component: NotFound },
 
];

export default pageList;
