import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Carousel, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Image} from 'antd';

import Masonry from 'react-masonry-css';

import PsContext from '../../context/PsContext';

const styles = {
	img:{
		width: '80px',
		height: '80px',
		borderRadius: '100px',
		padding: '3px',
		backgroundColor: '#fff',
	}
}

class RecentTestimonials extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			dataList: [],
			dataView: [],
			editData: [],
			categories: [],
			selectedCategory: '',
		}
	}
	
	componentDidMount(){
		
		this.loadSliders();				
	}
	
	loadSliders(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'site/v1/home/recenttestimonials?groupname=homepage_testimonial&type=event').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						editData: res['data'].data,
						dataList: res['data'].posts,
						dataView: res['data'].posts,
						showLoader: false,
					});
				}
			});
		}
		catch(error){
			console.log(error);
		}
	}
	
	getData(field){
		try{
			var d = this.state.editData;
			return d[0][field];
		}
		catch(error){ }
	}

	render(){
		
		if(Object.keys(this.state.editData).length<1){
			return(
			<></>
			);
		}
		
		return(
		<section id="testimonials" className="testimonials cb_home_testomonial">
		  <div className="container"  data-aos="zoom-in" >
				
				<Carousel>
					{this.state.dataList.map((item, i)=>{
						console.log(item);
						return <Carousel.Item>
							<div className="testimonial-item">
							{item.image_path && item.image_path!=null && item.image_path.length>5 
								? <img src={Api.Url + item.image_path} style={styles.img} />
								: <i className="icofo icofont-user-male"></i>
							}
								<h3>{item.title}</h3>
								<h4>{item.sub_title}</h4>
								<p>
								  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
									{item.content}
								  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
								</p>
							</div>
						</Carousel.Item>;
					})
					}
				</Carousel>
				
		  </div>
		</section>
		);
	}
	
}
export default RecentTestimonials;
