import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {getls, upperCase} from '../../../utils';
import {Tooltip, Image} from 'antd';

import PsContext from '../../context/PsContext';

class TopHeader extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
		}		
		
	}
	
	componentDidMount(){
		
	}
	
	checkSocialLink(showName){
		try{
			var s = this.context.state.siteCompany;
			return (s[showName]=='1');
		}
		catch(error){
			return false;
		}
	}
	
	getSocialLink(linkName){
		try{
			var s = this.context.state.siteCompany;
			return s[linkName];
		}
		catch(error){
			return false;
		}
	}
	
	handleLogoutClick(){
		
		if(!window.confirm('Do you want to logout?')){
			return false;
		}
		
		this.context.logout();
	}
	
	render(){
		
		return(
			<div id="topbar" className="d-none d-lg-flex align-items-center fixed-top">
				<div className="container d-flex">
				  <div className="contact-info mr-auto ">
					<nav className="nav-menu d-none d-lg-block">
					<ul>
					  <li><a href="https://tvmc.edu.in" className="link-with-campus" >Education</a></li>
					  <li ><a href="#">Research</a></li>
					  <li><a href="#">General Hospital</a></li>
					  <li><a href="#">Super-Speciality Hospital</a></li>
					  {this.context.state.isLogged!='YES' && (<li><Link to="/campus-firstline" >Campus Firstline</Link></li>)}
					  {this.context.state.isLogged=='YES' && (<li><a href="javascript:;" onClick={this.handleLogoutClick.bind(this)} >Sign Out</a></li>)}

					</ul>
				  </nav>
				  <p className="d-block d-sm-none webselect "><select className="custom-select ">
					  <option selected>Medical College</option>
					  <option value="1">Research & Development</option>
					  <option value="2">Tirunelveli Old Hospital</option>
					  <option value="3">Super Speciality Hospital</option>
					</select></p>
				  </div>
				  <div className="d-none d-lg-block social-links">
					{this.checkSocialLink('show_twitter') ? 
						<a href={this.getSocialLink('twitter')} className="twitter" target="_blank" ><i className="icofont-twitter"></i></a> 
					: null}
					{this.checkSocialLink('show_facebook') ? 
						<a href={this.getSocialLink('facebook')} className="facebook" target="_blank"  ><i className="icofont-facebook"></i></a>
					: null}
					{this.checkSocialLink('show_instagram') ? 
						<a href={this.getSocialLink('instagram')} className="instagram" target="_blank"  ><i className="icofont-instagram"></i></a>
					: null}
					{this.checkSocialLink('show_youtube') ? 
						<a href={this.getSocialLink('youtube')} className="youtube" target="_blank"  ><i className="icofont-youtube"></i></a>
					: null}
					{this.checkSocialLink('show_skype') ? 
						<a href={this.getSocialLink('skype')} className="skype" target="_blank"  ><i className="icofont-skype"></i></a>
					: null}
					{this.checkSocialLink('show_linkedin') ? 
						<a href={this.getSocialLink('linkedin')} className="linkedin" target="_blank"  ><i className="icofont-linkedin"></i></a>
					: null}
				  </div>
				</div>
			  </div>
		);
	}
	
}
export default TopHeader;
