import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {getls, upperCase} from '../../../utils';
import {Tooltip, Image} from 'antd';
import {Helmet} from "react-helmet";
import {Api} from '../../../utils/Variables';
import axios from 'axios';

import AOS from 'aos';

import PsContext from '../../context/PsContext';
import TopHeader from './TopHeader';
import Menu from './Menu';

class Header extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			dataList: [],
		}		
		
		this.handleDropDownMenuClick = this.handleDropDownMenuClick.bind(this);
	}
	
	componentDidMount(){
		
		 //window.addEventListener('scroll', this.handleWindowScroll);
		 this.loadMenus();
		
			/* AOS.init({
			  offset: 200,
			  duration: 1200,
			  easing: 'ease-in-sine',
			  delay: 1000,
			  once: false,
			});
			AOS.refresh(); */
			 AOS.init({
			  duration : 2000
			})
	}
	
	
	loadMenus(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'site/v1/menus').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						showLoader: false					
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleLogOut(){
		
		if(!window.confirm('Do you want to logout?')){
			return;
		}
		
		this.context.logout();
	}
	
	handleFormSubmit(e){
		e.preventDefault();
	}
	
	
	getPageTitle = () => {
		let name;
		this.props.routes.map(prop => {	

		  if (prop.path === this.props.location.pathname) {
			name = prop.name;
		  }
		  return null;
		});
		return name;
	};
	
	handleSidebarCollapse(){
		$("body").addClass("sidebar-expand");
		try{
			if($("body").hasClass("sidebar-collapse")){
				$("body").removeClass("sidebar-collapse");
				$("body").addClass("sidebar-expand");
			}
			else{
				$("body").removeClass("sidebar-expand");
				$("body").addClass("sidebar-collapse");
			}
		}
		catch(error){}
	}
	
	handleDropDownMenuClick(){
		try{
			
			var m = $("#cb__top__shortmenu");
			if($(m).hasClass("show")){
				$(m).removeClass("show");
			}
			else{
				$(m).addClass("show");
			}
		}
		catch(error){ }
	}

	
	render(){
		
		return(
			<React.Fragment>
				<Helmet>
					<link href={Api.Url + this.context.state.siteCompany.favicon} rel="icon" />
				</Helmet>
				
				<TopHeader />
				
				<header id="header" className="fixed-top">
					<div className="container d-flex align-items-center">

					<h1 className="logo mr-auto">
						<Link to="/" >
						{/*<img src={Api.Url + this.context.state.siteCompany.logo} className="logo-img"/>*/}
						<Image
							src={Api.Url + this.context.state.siteCompany.logo} 
							preview={false}
							className="logo-img"
							placeholder={
								<Image src={require('../../../assets/img/ph.jpg')} />
							}
							fallback={require('../../../assets/img/ph.jpg')}
						/>
						</Link>
					</h1>
					  
					  
				<div className="dropdown show d-block d-sm-none weblinks cb_home_header ">
				  <a className="btn btn-warning dropdown-toggle" href="javascript:;" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.handleDropDownMenuClick} >
				   
				  </a>
				  <div className="dropdown-menu " id="cb__top__shortmenu" aria-labelledby="dropdownMenuLink">
					<a className="dropdown-item" href="#">Education</a>
					<a className="dropdown-item" href="#">Research & Development</a>
					<a className="dropdown-item" href="#">Tirunelveli Old Hospital</a>
					<a className="dropdown-item" href="#">Super Speciality Hospital</a>
				  </div>
				</div>
				
				{/*<a href="javascript:;" className="mobile-nav-toggle d-lg-none" >
					<i className="icofont-navigation-menu"></i>
				</a>*/}
					  
					  {/*<ul>
						  <li><a href="#">About</a></li>
						  <li><a href="#">News</a></li>
						  <li><a href="#">Events</a></li>
						  <li><a href="#">Call for Proposals</a></li>
						  <li className="drop-down"><a href="">Schemes</a>
							<ul>
							  <li><a href="#">Instructions</a></li>
							  <li className="drop-down"><a href="#">UG Courses</a>
								<ul>
								  <li><a href="#">Deep Drop Down 1</a></li>
								  <li><a href="#">Deep Drop Down 2</a></li>
								  <li><a href="#">Deep Drop Down 3</a></li>
								  <li><a href="#">Deep Drop Down 4</a></li>
								  <li><a href="#">Deep Drop Down 5</a></li>
								</ul>
							  </li>
							  <li className="drop-down"><a href="#">PG Courses</a>
								<ul>
								  <li><a href="#">Deep Drop Down 1</a></li>
								  <li><a href="#">Deep Drop Down 2</a></li>
								  <li><a href="#">Deep Drop Down 3</a></li>
								  <li><a href="#">Deep Drop Down 4</a></li>
								  <li><a href="#">Deep Drop Down 5</a></li>
								</ul>
							  </li>
							  <li><a href="#">Diploma Courses</a></li>
							  <li><a href="#">Certificate Courses</a></li>
							</ul>
						  </li>
						  <li><a href="#">Gallery</a></li>
						  <li><a href="#">Contact</a></li>

					  </ul>*/}
					  
					  	{this.state.showLoader ? null : <Menu data={this.state.dataList} />}

					</div>
				  </header>
				
			</React.Fragment>
		);
	}
	
}
export default Header;
