import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Nav, Navbar  } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';

import PsContext from '../../context/PsContext';


class FullPageTopMenu extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			slug: '',
			dataList: [],
		}
	}
	
	componentDidMount(){
		
	}
	
	getData(field){
		try{
			var d = this.props.menus;
			return d[0][field];
		}
		catch(error){ }
	}
	
	getInnerLink()
	{
		var tr = [];
		this.props.menus.map((item,i)=>{
			tr.push(<NavLink to={`/${item.menulink}`} className="nav-link" >{item.title}</NavLink>);
		});
		return tr;
	}
	
	render(){

		return(
			<div className="page-top-menu" >
			{this.props.menus && (
				<Navbar >
					<Nav className="mr-auto ml-auto">
						{this.getInnerLink()}
					</Nav>
				</Navbar >
			)}
			</div>
		);
	}
	
}
export default FullPageTopMenu;
