import React, { Component } from 'react';
import { withRouter  } from "react-router-dom";
import $ from 'jquery';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';

import PsContext from '../../context/PsContext';
import FullPage from './FullPage';
import DeptWithRightSidebar from './DeptWithRightSidebar';
import RightSidebar from './RightSidebar';

import UserLogin from '../userLogin/userLogin';

class Pages extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			slug: '',
			dataList: [],
			
			images: [],
			photoIndex: 0,
			isOpen: false,
		}
		
		this.psImageGalleryImgClick = this.psImageGalleryImgClick.bind(this);
		this.psLinkClick = this.psLinkClick.bind(this);
		this.handleImgClick = this.handleImgClick.bind(this);
	}
	
	componentDidMount(){
		
		//handle image gallery click
		$(".ps-imagegallery").on('click', this.psImageGalleryImgClick);
		
		//handle link click 
		$(".ps-link").on('click', this.psLinkClick);
		
		$("img").on("contextmenu", this.handleImgClick);
		//$("img.ril__image").on("contextmenu", this.handleImgClick);
		$("img").on("dragstart", this.handleImgClick);
	}
	
	psImageGalleryImgClick(){
		try{
			var img = [];

			$(".ps-imagegallery img").each(function(){
	      		img.push($(this).attr('src'));
	    	});
	    	this.setState({
	    		images:img,
	    		isOpen: true,
	    	});
		}
		catch(er){}
	}

	psLinkClick(e)
	{
		e.preventDefault();
		try{
			
			var href = $(e.target).attr('href');
			const { history } = this.props;
			console.log(history);
			this.props.history.push(href);
		}
		catch(er){
			console.log(er);
		}
	}
	
	handleImgClick(e){
		
		e.preventDefault();
	}
	
	getData(field){
		try{
			
			var d = this.props.pagedata.page;
			return d[0][field];
		}
		catch(error){ }
	}
	
	
	render(){
		
		if(this.getData('access_with_login')=='1' && this.context.state.isLogged=='no')
		{
			return(
				<div className="cb__page__contents" >
					<h1 className="text-center font-weight-600 my-40 font-ff-meta-space"  data-aos="fade-down" >Campus Firstline</h1>
					<Row>
						<Col md={{ span:4, offset: 4}} >
							
							<UserLogin />
						
						</Col>
					</Row>
					
				</div>
			);
		}
		
		return(
			<React.Fragment>
				<Helmet>
					<title>{this.getData('title')  +" - "+ this.context.state.siteCompany.site_title}</title>
					<meta name="keyword" content={this.getData('meta_keyword') || this.context.state.siteCompany.site_keyword} />
					<meta name="description" content={this.getData('meta_description') || this.context.state.siteCompany.site_description} />
				</Helmet>
				
				{this.getData('template')=='full_page' ? <FullPage {...this.props} /> :null}
				{this.getData('template')=='dept_with_right_sidebar' ? <DeptWithRightSidebar {...this.props} /> :null}
				{this.getData('template')=='right_sidebar' ? <RightSidebar {...this.props} /> :null}
			
			
				{this.state.isOpen && (
				<Lightbox
					mainSrc={this.state.images[this.state.photoIndex]}
					nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
					prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
					onCloseRequest={() => this.setState({ isOpen: false })}
					onMovePrevRequest={() =>
					  this.setState({
						photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
					  })
					}
					onMoveNextRequest={() =>
					  this.setState({
						photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
					  })
					}
					/>)}
			
			</React.Fragment>
		);
	}
	
}
export default withRouter(Pages);
