import React, { Component } from 'react';
import $ from 'jquery';

import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import { getlsItem } from '../../../utils';
import {Avatar} from 'antd';

import PsContext from '../../context/PsContext';


class SideNav extends Component {
	
	static contextType = PsContext;
	
  constructor(props) {
    super(props);
    this.state = {

    };

    this.handleSidebarCollapse = this.handleSidebarCollapse.bind(this);
  }

  handleSidebarCollapse() {
    try {
      $('body').removeClass('sidebar-expand');
      $('body').addClass('sidebar-collapse');
    // eslint-disable-next-line no-console
    } catch (error) { console.log(error); }
  }

  render() {
    const navItems = (items) => items.map((item, index) => itemType(item, index));

    const itemType = (item, index) => {
		  if (item.children) {
			  if (item.allowed) {
				  if (item.allowed.indexOf(this.props.role) > -1) return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} role={this.props.role} />;
			  } else {
          return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} role={this.props.role} />;
			  }
		  } else if (item.divider) {
			return <NavDivider key={index} />;
		  } else if (item.allowed) {
				  if (item.allowed.indexOf(this.props.role) > -1) return <NavSingleItem item={item} key={index} />;
			  } else { return <NavSingleItem item={item} key={index}  />; }
    };

    return (
      <div className="sidebar ">
        <div className="sidebar-header">
          {/*<a href="javascript:;" className="logo">
		  <img src={LOGO} alt="Logo" id="main-logo" style={{width: '100%', height: 'auto' }}  />		  
          </a>*/}
		    <Avatar size="" style={{backgroundColor: "#0b85e6"}} >P</Avatar>
		  <div className="cb__cmp__name">{this.context.state.loggedCompany.name}</div>
          <a href="javascript:;" className="nav-link nav-icon rounded-circle ml-auto" data-toggle="sidebar" onClick={this.handleSidebarCollapse}>
            <i className="material-icons">close</i>
          </a>
        </div>
        <div className="sidebar-body">
          <ul className="nav nav-sub" style={{ width: '100%' }}>
            {navItems(this.props.nav.top)}
          </ul>
        </div>
      </div>
    );
  }
}
export default SideNav;
